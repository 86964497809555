import { Grid, Box } from '@material-ui/core'
import { Card, Label } from 'components'

import helpers from 'helpers'

import useStyles from './styles'

const ShowPayment = ({ paymentOrder }) => {
  const classes = useStyles()
  const handleDownload = (link) => window.open(link, '__blank')

  return (
    <>
      <Card title="Informações gerais">
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <Label title="ID" description={paymentOrder?.id} />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Status"
                description={helpers.paymentOrders.chipStatusLabel(
                  paymentOrder?.status,
                )}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Numero do contrato"
                description={paymentOrder?.contractIdentifier}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Data do pagamento"
                description={
                  helpers.formatters.date(paymentOrder?.paidDate) ||
                  'Não disponível'
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Data de referência"
                description={
                  helpers.formatters.date(paymentOrder?.orderReferenceDate) ||
                  'Não disponível'
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Número da nota fiscal"
                description={paymentOrder?.dataNfe?.nNfse || 'Não disponível'}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Link da nota fiscal"
                tooltip={false}
                description={
                  paymentOrder?.dataNfe?.cUrlNfse ? (
                    <span
                      onClick={() =>
                        handleDownload(paymentOrder?.dataNfe?.cUrlNfse)
                      }
                      className={classes.link}
                    >
                      Baixar
                    </span>
                  ) : (
                    'Não disponível'
                  )
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Preço"
                description={
                  'R$ ' + helpers.formatters.currencyInput(paymentOrder?.amount)
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Identificador superfin"
                description={paymentOrder?.externalId || 'Não disponível'}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Identificador omie"
                description={
                  paymentOrder?.codeOrderServiceOmie || 'Não disponível'
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Código do cupom"
                description={paymentOrder?.couponCode || 'Não aplicado'}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Método de pagemento"
                description={paymentOrder?.paymentMethod || 'Não especificado'}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Gateway de pagamento"
                description={helpers.functions.capitalizeText(
                  paymentOrder?.gateway,
                )}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Data de criação"
                description={helpers.formatters.date(paymentOrder?.createdAt)}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Última Atualização"
                description={helpers.formatters.date(paymentOrder?.updatedAt)}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card title="Dados do cliente">
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <Label title="Nome" description={paymentOrder?.buyer?.name} />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label title="E-mail" description={paymentOrder?.buyer?.email} />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="CNPJ/CPF"
                description={helpers.formatters.cnpjAndCpfMask(
                  paymentOrder?.buyer?.document,
                )}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Endereço"
                description={`${paymentOrder?.buyer?.address?.street}, ${paymentOrder?.buyer?.address?.number}`}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Bairro"
                description={paymentOrder?.buyer?.address?.neighborhood}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Município, UF - País"
                description={`${paymentOrder?.buyer?.address?.city}, ${paymentOrder?.buyer?.address?.state} - ${paymentOrder?.buyer?.address?.country}`}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Label
                title="Código postal"
                description={helpers.formatters.cep(
                  paymentOrder?.buyer?.address?.postalCode,
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      {paymentOrder?.billet && (
        <Card title="Dados do boleto">
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Label
                  title="Token"
                  description={paymentOrder?.billet?.token}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Label
                  title="Data de emissão pelo gateway"
                  description={helpers.formatters.date(
                    paymentOrder?.billet?.emissionDate,
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Label
                  title="Data de vencimento"
                  description={helpers.formatters.date(
                    paymentOrder?.billet?.expirationDate,
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Label
                  title="Linha digitável"
                  description={paymentOrder?.billet?.digitableLine}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Label
                  title="Link do boleto"
                  tooltip={false}
                  description={
                    paymentOrder?.billet?.url ? (
                      <span
                        onClick={() =>
                          handleDownload(paymentOrder?.billet?.url)
                        }
                        className={classes.link}
                      >
                        Baixar
                      </span>
                    ) : (
                      'Não disponível'
                    )
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      )}
    </>
  )
}

export default ShowPayment
