import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import useStyles from './styles'

import { Editor } from '@tinymce/tinymce-react'

import { getEditorTyneMceToken } from 'service/env'

const TextEditor = ({ onChange, value }) => {
  const classes = useStyles()

  const handleEditorChange = (editor) => onChange(editor)

  return (
    <Box className={classes.editor}>
      <Editor
        onEditorChange={handleEditorChange}
        apiKey={getEditorTyneMceToken()}
        init={{
          height: 500,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent ',
          language: 'pt_BR',
        }}
        value={value}
      />
    </Box>
  )
}

TextEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default TextEditor
