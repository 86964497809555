import { useState } from 'react'

import { Button } from '@material-ui/core'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton } from 'components'
import { ConfirmationLoss } from 'components/PaymentOrderButtonAction/components'

import helpers from 'helpers'

import PropTypes from 'prop-types'

const DelinquencyTableRow = ({ paymentOrder, refresh }) => {
  const [openConfirmatedLoss, setOpenConfirmatedLoss] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell>
          {helpers.formatters.date(paymentOrder?.orderReferenceDate, 'MM/YYYY')}
        </TableCell>
        <TableCell>
          {helpers.formatters.date(paymentOrder?.billet?.expirationDate)}
        </TableCell>
        <TableCell align="center">R$ {paymentOrder?.billet?.price}</TableCell>
        <TableCell align="center" disableTooltip>
          <MenuButton>
            <Button
              fullWidth
              size="small"
              onClick={() => {
                setOpenConfirmatedLoss(true)
              }}
            >
              Confirmar perda
            </Button>
          </MenuButton>
        </TableCell>
      </TableRow>
      <ConfirmationLoss
        paymentOrderId={paymentOrder?.id}
        open={openConfirmatedLoss}
        setOpen={setOpenConfirmatedLoss}
        refresh={refresh}
      />
    </>
  )
}

DelinquencyTableRow.propTypes = {
  paymentOrder: PropTypes.object,
}

export default DelinquencyTableRow
