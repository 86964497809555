import * as yup from 'yup'

const schemaLastUpdate = yup.object().shape({
  title: yup.string().required(),
  subtitle: yup.string().required(),
  tag: yup.string().required(),
  description: yup.string().required(),
})

export default schemaLastUpdate
