import { TableContainer } from '@material-ui/core'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'

import ChurnsTableRow from '../ChurnsTableRow'

import PropTypes from 'prop-types'

const ChurnsTable = ({ churns }) => {
  return (
    <TableContainer>
      <Table emptyMessage="Nenhum churn foi encontrado.">
        <TableHead>
          <TableRow>
            <TableCell width="5%">ID</TableCell>
            <TableCell width="20%">Nome da empresa</TableCell>
            <TableCell width="15%">Documento</TableCell>
            <TableCell width="20%">Colaborador responsável</TableCell>
            <TableCell width="5%" align="center">
              Ticket
            </TableCell>
            <TableCell width="10%" align="center">
              Data de solicitação
            </TableCell>
            <TableCell width="15%" align="center">
              Status
            </TableCell>
            <TableCell width="5%" align="center">
              Ação
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {churns?.map((churn) => (
            <ChurnsTableRow churn={churn} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ChurnsTable.propTypes = {
  churns: PropTypes.object,
}

export default ChurnsTable
