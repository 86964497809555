import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted, PaymentOrderButtonAction } from 'components'

import helpers from 'helpers'
import useStyles from './styles'
import constants from 'constants/index'

const PaymentOrdersTableRow = ({ paymentOrder, refresh, setLoading }) => {
  const classes = useStyles()

  return (
    <>
      <TableRow key={paymentOrder?.id}>
        <TableCell disableTooltip>{paymentOrder?.id}</TableCell>
        <TableCell>{paymentOrder?.contractIdentifier}</TableCell>
        <TableCell disableTooltip>
          {paymentOrder?.paymentMethod || '-'}
        </TableCell>
        <TableCell>{paymentOrder?.preRegistration?.name}</TableCell>
        <TableCell disableTooltip>
          R$ {helpers.formatters.currencyInput(paymentOrder?.amount)}
        </TableCell>
        <TableCell disableTooltip align="left">
          {paymentOrder?.paymentMethod === constants.paymentOrder.BILLET_METHOD
            ? helpers.formatters.date(paymentOrder?.billet?.expirationDate)
            : '-'}
        </TableCell>
        <TableCell disableTooltip align="left">
          {helpers.formatters.date(paymentOrder?.paidDate) || '-'}
        </TableCell>
        <TableCell disableTooltip align="left">
          {helpers.formatters.date(
            paymentOrder?.orderReferenceDate,
            'MM/YYYY',
          ) || '-'}
        </TableCell>
        <TableCell>{paymentOrder?.dataNfe?.nNfse || '-'}</TableCell>
        <TableCell disableTooltip noOverFlow align="left">
          <Chip
            size="small"
            className={helpers.paymentOrders.chipStatusColor(
              paymentOrder?.status,
              classes,
            )}
            label={helpers.paymentOrders.chipStatusLabel(paymentOrder?.status)}
          />
        </TableCell>
        <TableCell disableTooltip noOverFlow>
          <Permitted tag={constants.permissions.PAYMENT_ORDERS.MANAGE_PAYMENT}>
            <MenuButton>
              <PaymentOrderButtonAction
                paymentOrder={paymentOrder}
                refresh={refresh}
                setLoading={setLoading}
                isList
              />
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

PaymentOrdersTableRow.propTypes = {
  paymentOrder: PropTypes.object,
}

export default PaymentOrdersTableRow
