import { Box, Button, FormControlLabel, Switch } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import { Card } from 'components'

import CheckoutLink from './CheckoutLink'
import Form from './Form'
import View from './View'

const CreditCard = ({
  paymentContract,
  disabled,
  control,
  errors,
  setValue,
  watch,
  sendEmails,
  setSendEmails,
  changeCard,
  setChangeCard,
}) => {
  const creditCard = paymentContract?.creditCard

  return (
    <Card
      title="Dados do cartão"
      actionButton={
        (!paymentContract?.havePaymentOrders ||
          paymentContract.payInBackoffice) && (
          <Button
            onClick={() => setChangeCard((prevChangeCard) => !prevChangeCard)}
            variant="contained"
            color="primary"
          >
            {changeCard ? 'Cancelar' : 'Utilizar outro cartão'}
          </Button>
        )
      }
    >
      {creditCard && !changeCard ? (
        <View creditCard={creditCard} />
      ) : (
        <>
          <Box mb={2}>
            <Controller
              as={
                <FormControlLabel
                  control={<Switch />}
                  label="Efetuar pagamento no backoffice"
                  disabled={paymentContract?.havePaymentOrders}
                />
              }
              control={control}
              name="payInBackoffice"
            />
          </Box>
          {watch('payInBackoffice') ? (
            <Form
              disabled={disabled}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          ) : (
            <CheckoutLink
              sendEmails={sendEmails}
              setSendEmails={setSendEmails}
              shouldSave={
                paymentContract?.methodPayment !== 'card' ||
                paymentContract?.payInBackoffice
              }
              url={paymentContract.checkoutLink}
            />
          )}
        </>
      )}
    </Card>
  )
}

export default CreditCard
