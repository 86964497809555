import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Grid, Box } from '@material-ui/core'

import { useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'
import { useCompaniesCache } from 'hooks/caches'

import { LoadingBox, ButtonComponent } from 'components'
import { Address, CompanyData, Responsible, TypeCompany } from './components'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'
import * as service from 'service'
import { reverse } from 'named-urls'

import schema from './schema'

const Form = ({
  isEdit,
  preRegistration,
  isSupplier,
  companySupplier,
  companySupplierIsJuridic,
  typeDefault,
  parentContractId,
  typeClient,
  createContract,
}) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()
  const history = useHistory()
  const companiesCache = useCompaniesCache()

  const { KIND_COMPANY, KIND_PERSONAL, TYPES_PRE_REGISTRATION } =
    constants.companies

  const kindCompany = preRegistration?.kind === KIND_COMPANY

  const {
    defaultEmail,
    defaultFantasyName,
    defaultKind,
    defaultPhone,
    defaultResponsible,
  } = helpers.preRegistrations.defaultValues(
    isSupplier,
    companySupplier,
    preRegistration,
    companySupplierIsJuridic,
  )

  const responsibleUserDefult = defaultResponsible()

  const defaultTypeClient =
    preRegistration?.typeClient ||
    typeClient ||
    constants.preRegistration.CLIENT_TYPE

  const isClient =
    defaultTypeClient !== constants.preRegistration.SUBLICENSE_TYPE

  const {
    handleSubmit,
    control,
    errors,
    watch,
    setValue,
    register,
    getValues,
  } = useForm({
    validationSchema: schema,
    defaultValues: {
      sellerId: preRegistration?.sellerId || '',
      endDate: preRegistration?.membershipRegistration?.endDate
        ? moment(preRegistration?.membershipRegistration?.endDate, 'DD/MM/YYYY')
        : null,
      kind: defaultKind(),
      type: typeDefault,
      postalCode: preRegistration?.address?.postalCode || '',
      country: preRegistration?.address?.country || 'Brasil',
      state: preRegistration?.address?.state || '',
      city: preRegistration?.address?.city || '',
      neighborhood: preRegistration?.address?.neighborhood || '',
      street: preRegistration?.address?.street || '',
      number: preRegistration?.address?.number || '',
      complement: preRegistration?.address?.complement || '',
      fantasyName: defaultFantasyName(),
      name: kindCompany ? preRegistration?.name : '',
      document: preRegistration?.document || '',
      phone: defaultPhone(),
      email: defaultEmail(),
      responsibleName: responsibleUserDefult?.name || '',
      responsibleEmail: responsibleUserDefult?.email || '',
      responsiblePhone: responsibleUserDefult?.phone || '',
      planId: null,
      segmentId: preRegistration?.plan?.segmentId || '',
      typeService:
        preRegistration?.typeService ||
        constants.paymentContract.DIGITAL_SALES_LABEL,
      vertical:
        preRegistration?.vertical || constants.paymentContract.SERVICES_LABEL,
      typeClient: defaultTypeClient,
      companyTemplate: preRegistration?.companyTemplate || '',
      typeTemplate: constants.preRegistration.COPY_COMPANY_PLAN_TYPE,
    },
    shouldUnregister: false,
  })

  const typeIsTrial = watch('type') === TYPES_PRE_REGISTRATION.TRIAL
  const typeIsReal = watch('type') === TYPES_PRE_REGISTRATION.REAL

  useEffect(() => {
    if (isSupplier) {
      if (companySupplierIsJuridic) {
        setValue('kind', KIND_COMPANY)
        register('kind')
      } else {
        setValue('kind', KIND_PERSONAL)
        register('kind')
      }
    }
    //eslint-disable-next-line
  }, [companySupplierIsJuridic])

  const verify_mount = (data) => {
    if (typeIsReal)
      return helpers.preRegistrations.mountReal(data, parentContractId)

    return helpers.preRegistrations.mountTrial(data)
  }

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      let method = isEdit ? 'update' : 'create'

      const response = await service.dponet.preRegistrations[method]({
        preRegistrationToken: preRegistration?.token,
        pre_registration: verify_mount(data),
      })

      if (isSupplier) {
        companiesCache.useUpdateCache(companySupplier?.id)

        return history.push(
          reverse(routes.companies.edit, { companyId: companySupplier?.id }),
        )
      }

      if (parentContractId) {
        snackbar.open({
          message: `${
            isClient ? 'Cliente' : 'sublicenciamento'
          } de criado com sucesso e vinculado ao contrato!`,
          variant: 'success',
        })

        return history.push(
          reverse(routes.contracts.edit, {
            contractId: parentContractId,
          }),
          {
            tab: 'contractor',
          },
        )
      }

      if (createContract) {
        snackbar.open({
          message: `${
            isClient ? 'Cliente' : 'sublicenciamento'
          } Contratante de criado com sucesso! Agora você sera redirecionado para o contrato`,
          variant: 'success',
        })

        return history.push(routes.contracts.new, {
          preRegistrationToken: response?.data?.preRegistration?.token,
        })
      }

      snackbar.open({
        message: `${
          isClient ? 'Cliente' : 'sublicenciamento'
        } Contratante de criado com sucesso!`,
        variant: 'success',
      })

      history.push(
        reverse(routes.preRegistrations.show, {
          preRegistrationToken: response?.data?.preRegistration?.token,
        }),
      )
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `Erro ao ${isEdit ? 'editar' : 'criar'} o ${
            isClient ? 'Cliente' : 'sublicenciamento'
          } de empresa!`,
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const editionPermitted = preRegistration?.isRelatedCompany

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <form
              id="id-form-company-trial-and-real"
              onSubmit={handleSubmit(onSubmit)}
            >
              <TypeCompany
                control={control}
                preRegistration={preRegistration}
                errors={errors}
                typeIsTrial={typeIsTrial}
                setValue={setValue}
                watch={watch}
                isEdit={isEdit}
              />
              <CompanyData
                control={control}
                errors={errors}
                setValue={setValue}
                isTrialCompany={typeIsTrial}
                watch={watch}
                preRegistration={preRegistration}
                isEdit={isEdit}
                companySupplierIsJuridic={companySupplierIsJuridic}
                isSupplier={isSupplier}
                companySupplier={companySupplier}
                disableCertFields={editionPermitted}
              />
              <Address
                control={control}
                errors={errors}
                getValues={getValues}
                addressData={preRegistration?.address}
                setValue={setValue}
                watch={watch}
              />
              <Responsible control={control} errors={errors} watch={watch} />
              <Box display="flex" justifyContent="flex-end">
                <ButtonComponent title="Salvar" />
              </Box>
            </form>
          </Grid>
        </Grid>
      )}
    </>
  )
}

Form.propTypes = {
  isEdit: PropTypes.bool,
  preRegistration: PropTypes.object,
}

export default Form
