import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import clsx from 'clsx'
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Grid,
  TextField,
} from '@material-ui/core'
import { UploadCloud as UploadCloudIcon } from 'react-feather'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

import { Label, MenuButton, BaseDialog, Select } from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { Controller, useForm } from 'react-hook-form'
import { useAuth, useSnackbar } from 'hooks'

import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import * as service from 'service'

const ConfirmationPayment = ({
  open,
  setOpen,
  refresh,
  setLoading,
  paymentOrder,
}) => {
  const snackbar = useSnackbar()
  const [voucherFile, setVoucherFile] = useState(null)
  const auth = useAuth()

  const classes = useStyles()
  const refInput = useRef(null)

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      description: '',
      confirmationDatePaid: moment().format('YYYY-MM-DD'),
      gateway: 'superfin',
      paymentMethod: 'Boleto',
    },
  })

  const handleChange = (event) => {
    let files = event.target.files
    setVoucherFile(files[0])
  }

  const handleRemove = () => {
    refInput.current.value = ''
    setVoucherFile(null)
  }

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      await service.dponet.preRegistrations.confirmationPaid(
        helpers.paymentOrders.mountConfirmationPay(
          data,
          voucherFile,
          paymentOrder.id,
        ),
      )

      snackbar.open({
        message: 'Pagamento confirmado com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao confirmar o pagamento, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setVoucherFile(null)
      setOpen(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      title="Confirmar pagamento"
      actionButtonText="Confirmar"
      closeButtonText="Cancelar"
      setOpen={setOpen}
      formRef="id-confirm-paid"
    >
      <Box>
        <Box mt={1} mb={3}>
          <Label title="Usuário responsável pela aprovação">
            <Typography variant="body2">{auth?.user?.name || ''}</Typography>
          </Label>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} id="id-confirm-paid">
          <Grid container spacing={2}>
            <Grid item xs="12">
              <Controller
                as={
                  <DatePicker
                    label="Data do pagamento"
                    color="primary"
                    format="DD/MM/YYYY"
                    variant="outlined"
                    fullWidth
                    onChange={(newValue) => ({ value: newValue })}
                  />
                }
                control={control}
                name="confirmationDatePaid"
                mode="onChange"
              />
            </Grid>
            <Grid item xs="12">
              <Controller
                as={
                  <Select
                    items={constants.paymentOrder.GATEWAYES}
                    label="Intermediador de pagamento"
                  />
                }
                control={control}
                name="gateway"
                mode="onChange"
              />
            </Grid>
            <Grid item xs="12">
              <Controller
                as={
                  <Select
                    items={constants.paymentOrder.PAYMENT_METHODS}
                    label="Forma de pagamento"
                    anotherOption
                  />
                }
                control={control}
                name="paymentMethod"
                mode="onChange"
              />
            </Grid>
            {watch('paymentMethod') === 'another' && (
              <Grid item xs="12">
                <Controller
                  as={
                    <TextField
                      fullWidth
                      label="Especifique a forma de pagamento"
                    />
                  }
                  control={control}
                  name="paymentMethodOther"
                  mode="onChange"
                  required
                />
              </Grid>
            )}
            <Grid item xs="12">
              <Controller
                as={
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    label="Observação"
                  />
                }
                control={control}
                name="description"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs="12">
              <Label
                title="Comprovante de pagamento"
                description="Arquivos suportados: jpg, jpeg, png e pdf."
              >
                <Controller
                  as={
                    <Button
                      disabled={!isNil(voucherFile)}
                      variant="contained"
                      component="label"
                      size="small"
                      className={clsx(classes.uploadButtonBase, {
                        [classes.uploadButtonDisabed]: !isNil(voucherFile),
                      })}
                    >
                      <Box pl={1} pt={1}>
                        <UploadCloudIcon size="16px" />
                      </Box>
                      <Box px={1}>
                        <Typography variant="h6">UPLOAD</Typography>
                      </Box>
                      <input
                        type="file"
                        ref={refInput}
                        multiple
                        id="voucherFiles"
                        accept="image/jpg,image/jpeg,image/png,application/pdf"
                        className={classes.input}
                      />
                    </Button>
                  }
                  control={control}
                  mode="onChange"
                  onChange={([event]) => handleChange(event)}
                  name="voucherFiles"
                />
              </Label>
            </Grid>
            <Grid item xs="12">
              <Box display="flex" alignItems="center">
                {!isNil(voucherFile) && (
                  <TableContainer>
                    <Table
                      size="small"
                      emptyMessage="Nenhum documento encontrado"
                      noWrap
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Comprovante</TableCell>
                          <TableCell align="right">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={1}>
                          <TableCell disableTooltip>
                            {voucherFile.name}
                          </TableCell>
                          <TableCell disableTooltip align="right">
                            <Box display="flex" justifyContent="flex-end">
                              <MenuButton>
                                <Button
                                  color="secondary"
                                  fullWidth
                                  onClick={handleRemove}
                                >
                                  Remover
                                </Button>
                              </MenuButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </BaseDialog>
  )
}

ConfirmationPayment.propTypes = {
  voucherFile: PropTypes.object,
  setVoucherFile: PropTypes.func,
}

export default ConfirmationPayment
