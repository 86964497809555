import { useState } from 'react'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'

import DialogLogs from 'components/DialogLogs'

import { useFetch } from 'hooks'

import * as service from 'service'

const ButtonLogs = ({ routes, entityId }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)

  const { response, isLoading } = useFetch(service.dponet.logs, {
    routes,
    entityId,
  })

  const logs = response?.data?.dponetAudits

  return (
    <>
      <Button fullWidth size="small" onClick={handleOpen}>
        Histórico
      </Button>
      {open && (
        <DialogLogs
          setOpen={setOpen}
          open={open}
          logs={logs || []}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

ButtonLogs.propTypes = {
  routes: PropTypes.string.isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default ButtonLogs
