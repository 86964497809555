import PropTypes from 'prop-types'
import clsx from 'clsx'

import { TableCell as TableCellMaterial, Box, Tooltip } from '@material-ui/core'

import styles from './styles'

export const DEFAULT_BODY_SKELETON_HEIGHT = 20

const TableCell = ({
  bodySkeletonHeight,
  children,
  overFlowSize,
  disableTooltip,
  noOverFlow,
  pointer,
  className,
  ...rest
}) => {
  const useStyles = styles(overFlowSize)

  const classes = useStyles()

  return (
    <TableCellMaterial
      className={clsx({
        [classes.pointer]: pointer,
        [className]: !!className,
      })}
      {...rest}
    >
      {children && (
        <Tooltip
          disableFocusListener={disableTooltip}
          disableHoverListener={disableTooltip}
          disableTouchListener={disableTooltip}
          title={children}
        >
          <Box className={noOverFlow ? classes.noOverFlow : classes.overFlow}>
            {children}
          </Box>
        </Tooltip>
      )}
    </TableCellMaterial>
  )
}

TableCell.propTypes = {
  bodySkeletonHeight: PropTypes.number,
  overFlowSize: PropTypes.string,
  disableTooltip: PropTypes.bool,
  noOverFlow: PropTypes.bool,
  pointer: PropTypes.string,
  className: PropTypes.string,
}

TableCell.defaultProps = {
  bodySkeletonHeight: DEFAULT_BODY_SKELETON_HEIGHT,
  overFlowSize: '200px',
  disableTooltip: false,
  noOverFlow: false,
}

export default TableCell
