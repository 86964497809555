import { useState } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box, Button } from '@material-ui/core'
import { Card, ConfirmationDialog, MenuButton } from 'components'

import { isEmpty } from 'lodash'

import { useSnackbar } from 'hooks'

import { Plus as PlusIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { CreatePlanDialog } from './components'

import constants from 'constants/index'
import * as service from 'service'

import helpers from 'helpers'

const UpgradePlansTable = ({
  upgradePlans,
  plans,
  refresh,
  integrationPartner,
  setUpgradePlans,
}) => {
  const snackbar = useSnackbar()
  const [openCreate, setOpenCreate] = useState(false)
  const [openDestroy, setOpenDestroy] = useState(false)
  const [integrationPartnerPlan, setIntegrationPartnerPlan] = useState()

  const handleCreate = () => setOpenCreate(true)
  const handleEdit = (upgradePlan) => {
    setOpenCreate(true)
    setIntegrationPartnerPlan(upgradePlan)
  }

  const handleDestroy = (upgradePlan) => {
    setOpenDestroy(true)
    setIntegrationPartnerPlan(upgradePlan)
  }

  const destroy = async () => {
    try {
      await service.dponet.integrationPartnerPlans.destroy(
        integrationPartnerPlan?.id,
      )
      snackbar.open({
        message: 'O plano de upgrade foi removido com sucesso!',
        variant: 'success',
      })
      setTimeout(setIntegrationPartnerPlan, 200)
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  return (
    <Card
      title="Planos de upgrade"
      actionButton={
        <Button
          variant="outlined"
          color="primary"
          disabled={isEmpty(plans)}
          startIcon={<PlusIcon />}
          onClick={handleCreate}
        >
          Criar
        </Button>
      }
      dropdown
      defaultExpanded
    >
      <Box width="100%">
        <PerfectScrollbar
          options={{
            wheelPropagation: true,
            useBothWheelAxes: true,
            suppressScrollY: false,
          }}
        >
          <Table emptyMessage="Nenhum plano de upgrade encontrado" noWrap>
            <TableHead>
              <TableRow>
                <TableCell width="50%" align="left">
                  Nome
                </TableCell>
                <TableCell width="20%" align="left">
                  Valor
                </TableCell>
                <TableCell width="20%" align="left">
                  Plano do banner de cookies
                </TableCell>
                <TableCell width="10%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {upgradePlans?.map((upgradePlan, index) => (
                <TableRow key={upgradePlan?.id ?? index}>
                  <TableCell
                    noOverFlow
                  >{`${upgradePlan?.plan?.name} - ${upgradePlan?.plan?.code}`}</TableCell>
                  <TableCell>R$ {upgradePlan?.plan?.price}</TableCell>
                  <TableCell>
                    {
                      constants.integrationPartner.COOKIE_LABELS[
                        upgradePlan?.cookiePlan
                      ]
                    }
                  </TableCell>
                  <TableCell align="right" disableTooltip>
                    <MenuButton>
                      <Button
                        size="small"
                        onClick={() => handleEdit(upgradePlan)}
                      >
                        EDITAR
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleDestroy(upgradePlan)}
                      >
                        Excluir
                      </Button>
                    </MenuButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
      <CreatePlanDialog
        plans={plans}
        open={openCreate}
        setOpen={setOpenCreate}
        integrationPartner={integrationPartner}
        refresh={refresh}
        setUpgradePlans={setUpgradePlans}
        integrationPartnerPlan={integrationPartnerPlan}
        setIntegrationPartnerPlan={setIntegrationPartnerPlan}
      />
      <ConfirmationDialog
        open={openDestroy}
        setOpen={setOpenDestroy}
        message={`Você tem certeza que deseja excluir o plano de upgrade?`}
        buttonText="Confirmar"
        actionAcceptButton={destroy}
      />
    </Card>
  )
}

export default UpgradePlansTable
