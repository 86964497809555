import { Typography, Grid } from '@material-ui/core'
import { Select } from 'components'

import { Controller, useForm } from 'react-hook-form'

import constants from 'constants/index'

import schema from './schema'

const Form = ({ integrationPartnerPlan, plans, onSubmit }) => {
  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema(),
    defaultValues: {
      planId: integrationPartnerPlan?.plan?.id || '',
      cookiePlan: integrationPartnerPlan?.cookiePlan || '',
    },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="id-new-plan-upgrade">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Plano:</Typography>
          <Controller
            control={control}
            name="planId"
            mode="onChange"
            as={
              <Select
                items={plans ?? []}
                error={!!errors.planId}
                helperText={errors?.planId?.message}
                variant="outlined"
                fullWidth
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Plano do banner de cookies:</Typography>
          <Controller
            control={control}
            name="cookiePlan"
            mode="onChange"
            as={
              <Select
                items={
                  constants.integrationPartner
                    .SELECT_COOKIE_PLAN_INTEGRATION_PARTNER ?? []
                }
                error={!!errors.cookiePlan}
                helperText={errors?.cookiePlan?.message}
                variant="outlined"
                fullWidth
              />
            }
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
