/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'

import { Card, LoadingBox } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import { routes } from 'Routes'
import schema from './schema'
import useStyles from './styles'

import constants from 'constants/index'
import { isEmpty, isUndefined } from 'lodash'
import { TextEditor } from './components'

const FormLastUpdate = ({ lastUpdate, isEdit }) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [draft, setDraft] = useState(true)

  const { handleSubmit, errors, control, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      tag: lastUpdate?.tag ?? '',
      title: lastUpdate?.title ?? '',
      subtitle: lastUpdate?.subtitle ?? '',
      description: lastUpdate?.description ?? '',
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      if (!isUndefined(draft) || !isEmpty(draft)) {
        if (isEdit) {
          await service.dponet.lastUpdates.put({
            lastUpdateId: lastUpdate.id,
            draft: draft,
            ...data,
          })
        } else {
          await service.dponet.lastUpdates.create({
            draft: draft,
            ...data,
          })
        }
      }
      snackbar.open({
        message: `Atualização ${isEdit ? 'editada' : 'criada'} com sucesso`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error.response.data.error,
          false,
        ),
        variant: 'error',
      })
    }
    setLoading(false)
    reset(data)
    history.push(routes.lastUpdates.all)
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card title={`${isEdit ? 'Editar' : 'Adicionar nova'} atualização`}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography>Tipo:</Typography>
                <Controller
                  control={control}
                  name="tag"
                  as={
                    <RadioGroup row>
                      <FormControlLabel
                        value={constants.lastUpdates.KINDS_LABELS.N}
                        control={<Radio />}
                        label="Novidade"
                      />
                      <FormControlLabel
                        value={constants.lastUpdates.KINDS_LABELS.I}
                        control={<Radio />}
                        label="Melhoria"
                      />
                    </RadioGroup>
                  }
                />
                {errors.tag && (
                  <Typography className={classes.errorDescriptionMessage}>
                    Preencha esse campo
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>Título</Typography>
                <Controller
                  as={
                    <TextField
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.title}
                      helperText={errors?.title?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="title"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Subtítulo</Typography>
                <Controller
                  as={
                    <TextField
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.subtitle}
                      helperText={errors?.subtitle?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="subtitle"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={({ onChange, value }) => (
                    <TextEditor onChange={onChange} value={value} />
                  )}
                  control={control}
                  name="description"
                  mode="onChange"
                />
                {errors.description && (
                  <Typography className={classes.errorDescriptionMessage}>
                    Preencha esse campo
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Box className={classes.boxBackSave}>
              <Box pr={1}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => setDraft(true)}
                >
                  SALVAR RASCUNHO
                </Button>
              </Box>
              <Box>
                <Button
                  className={classes.buttonSave}
                  variant="contained"
                  type="submit"
                  onClick={() => setDraft(false)}
                >
                  PUBLICAR
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </>
  )
}
FormLastUpdate.propTypes = {
  isEdit: PropTypes.bool,
  lastUpdate: PropTypes.object,
}

FormLastUpdate.defaultProps = {
  isEdit: false,
  lastUpdate: {},
}

export default FormLastUpdate
