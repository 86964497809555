import constants from 'constants/companies'

const statusLabel = (status, platform) => {
  if (platform === 'dponet') {
    return constants.STATUSES_LABELS[status]
  }

  switch (status) {
    case constants.STATUSES.ACTIVE:
      return constants.STATUSES_LABELS[constants.STATUSES.ACTIVE]
    case constants.STATUSES.INACTIVE:
      return 'Em implementação'
    default:
      return constants.STATUSES_LABELS[status]
  }
}

export default statusLabel
