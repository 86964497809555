import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chipBlack: {
    backgroundColor: theme.palette.black,
    borderRadius: theme.spacing(1),
    color: theme.palette.white,
    fontWeight: 700,
    height: 21,
  },
  chipNotPaid: {
    backgroundColor: theme.palette.azure,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipWaitingPayment: {
    backgroundColor: theme.palette.yellowOrange,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipWaitingConfirm: {
    backgroundColor: theme.palette.yellowOrange,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipPaid: {
    backgroundColor: theme.palette.greenPantone,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipFailure: {
    backgroundColor: theme.palette.darkRed,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipCanceled: {
    backgroundColor: theme.palette.spanishGray,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  iconbutton: {
    '&:hover': {
      backgroundColor: theme.palette.azure,
      color: theme.palette.white,
    },
  },
  chipIntegration: {
    backgroundColor: theme.palette.blueViolet,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipLoss: {
    backgroundColor: theme.palette.cgRed,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
}))

export default styles
