import constants from 'constants/preRegistration'

const chipStatusColor = (status, classes) => {
  const statuses = {
    [constants.STATUSES.INITIATED_STATUS]: classes.chipInitiated,
    [constants.STATUSES.PAYMENT_FAILED_STATUS]: classes.chipPaymentFailed,
    [constants.STATUSES.FINALIZED_STATUS]: classes.chipFinalized,
    [constants.STATUSES.AWAITING_PAYMENT_STATUS]: classes.chipWaitingPayment,
    [constants.STATUSES.DEMO_STATUS]: classes.chipDemonstration,
    [constants.STATUSES.TRIAL_STATUS]: classes.chipTrial,
    [constants.STATUSES.TRIAL_CANCELED_STATUS]: classes.chipTrialCanceled,
    [constants.STATUSES.OVERDUE_STATUS]: classes.chipPaymentFailed,
    [constants.STATUSES.AWAITING_RESPONSE_STATUS]: classes.chipAwaitingResponse,
  }

  return statuses[status] || null
}

export default chipStatusColor
