import { useState, useEffect } from 'react'
import {
  Box,
  Card,
  Divider,
  Typography,
  TablePagination,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'

import {
  Page,
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
} from 'components'
import { QuestionnairesTable, QuestionnaireFilter } from './components'

import { useFilter, usePagination } from 'hooks'
import * as service from 'service'

const QuestionnairesMain = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [questionnaires, setQuestionnaires] = useState()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const filter = useFilter()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(12)

  const loadQuestionnaires = async () => {
    if (!isLoading) setIsLoading(true)

    return await service.dponet.questionnaires.get({
      ...filter.filters,
      perPage,
      page,
    })
  }

  useEffect(() => {
    loadQuestionnaires()
      .then((response) => setQuestionnaires(response?.data ?? []))
      .finally(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, filter.filters, filter.reload])

  return (
    <Page title="Questionários por empresa">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Questionários por empresa"
          title="Listagem"
          subtitle="Questionários por empresa"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>

        <Card>
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            p={2}
          >
            <Typography variant="h5">Questionários por empresa</Typography>
          </Box>

          <Divider />

          <QuestionnairesTable
            questionnaires={questionnaires?.questionnaires ?? []}
          />

          {!isLoading && questionnaires?.meta ? (
            <TablePagination
              component="div"
              count={questionnaires.meta.totalCount}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[4, 12, 36, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          ) : (
            <Box py="11px">
              <Skeleton variant="rect" width={200} height={30} />
            </Box>
          )}
        </Card>
      </Container>

      <QuestionnaireFilter filter={filter} />
    </Page>
  )
}

export default QuestionnairesMain
