import { Fragment } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'

import {
  LoadingFeedback,
  Select as SelectComponent,
  TextEditor,
} from 'components'

import { useFetch, useSnackbar } from 'hooks'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'
import schema from './schema'
import theme from 'theme'

const TechnicalFaqsForm = ({ setLoading, refresh, formData, isEdit }) => {
  const snackbar = useSnackbar()
  const { handleSubmit, errors, control, reset, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      categoryId: formData?.category?.id ?? '',
      subCategoryId: formData?.subCategory?.id ?? '',
      title: formData?.title ?? '',
      description: formData?.description ?? '',
    },
  })

  const categoryIdForm = watch('categoryId')

  const { response: categoriesResponse } = useFetch(
    service.dponet.technicalFaqs.getCategories,
    {},
  )

  const { response: subcategoriesResponse } = useFetch(
    service.dponet.technicalFaqs.getSubcategory,
    {
      categoryId: categoryIdForm,
      status: constants.technicalFaqs.ACTIVE_STATUS,
    },
    [categoryIdForm],
    !!categoryIdForm,
  )

  const categoriesFaqs = categoriesResponse?.data?.knowledgeBaseCategories

  const subcategoriesFaqs =
    subcategoriesResponse?.data?.knowledgeBaseSubcategories

  const categoriesFormattingFaqs =
    categoriesFaqs &&
    categoriesFaqs.map((category) => {
      return { id: category?.id, name: category?.title }
    })

  const subcategoriesFormattingFaqs =
    subcategoriesFaqs &&
    subcategoriesFaqs.map((category) => {
      return { id: category?.id, name: category?.title }
    })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isEdit) {
        await service.dponet.technicalFaqs.update({
          technicalFaqId: formData?.id,
          ...data,
        })
        refresh()
      } else {
        await service.dponet.technicalFaqs.create({ technical_faq: data })
        reset({ categoryId: '', subCategoryId: '', title: '', description: '' })
      }
      snackbar.open({
        message: `Base de conhecimento ${helpers.technicalFaqs.snackbarStatusTranslation(
          data?.status,
          true,
        )} com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingFeedback
        open={
          (!categoriesFormattingFaqs || !subcategoriesFormattingFaqs) && isEdit
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Categoria *</Typography>
          {(categoriesFormattingFaqs || !isEdit) && (
            <Controller
              as={
                <SelectComponent
                  variant="outlined"
                  items={categoriesFormattingFaqs ?? []}
                  error={!!errors.categoryId}
                  helperText={errors?.categoryId?.message}
                  fullWidth
                />
              }
              control={control}
              name="categoryId"
              mode="onChange"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Subcategoria *</Typography>
          {(subcategoriesFormattingFaqs || !isEdit) && (
            <Controller
              as={
                <SelectComponent
                  variant="outlined"
                  placeholder="Selecione a subcategoria"
                  items={subcategoriesFormattingFaqs ?? []}
                  error={!!errors.subCategoryId}
                  helperText={errors?.subCategoryId?.message}
                  fullWidth
                />
              }
              control={control}
              name="subCategoryId"
              mode="onChange"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Título *</Typography>
          <Controller
            as={
              <TextField
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.title}
                helperText={errors?.title?.message}
                fullWidth
              />
            }
            control={control}
            name="title"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Descrição *</Typography>
          <Controller
            as={({ onChange, value }) => (
              <Fragment>
                <TextEditor onChange={onChange} value={value} />
                {errors.description && (
                  <Box ml="14px">
                    <FormHelperText error={!!errors.description}>
                      {errors.description.message}
                    </FormHelperText>
                  </Box>
                )}
              </Fragment>
            )}
            control={control}
            name="description"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="end" gridGap={theme.spacing(1)}>
            <Button
              variant="contained"
              onClick={handleSubmit((data) =>
                onSubmit({
                  ...data,
                  status: constants.technicalFaqs.DRAFT_STATUS,
                }),
              )}
            >
              Salvar Rascunho
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit((data) =>
                onSubmit({
                  ...data,
                  status: constants.technicalFaqs.ACTIVE_STATUS,
                }),
              )}
            >
              Publicar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

TechnicalFaqsForm.propTypes = {
  setLoading: PropTypes.func,
  refresh: PropTypes.func,
  formData: PropTypes.object,
  isEdit: PropTypes.bool,
}

export default TechnicalFaqsForm
