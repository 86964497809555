import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import { Box } from '@material-ui/core'
import { reverse } from 'named-urls'
import moment from 'moment'

import { useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import {
  LoadingFeedback,
  Permitted,
  InfoDialog,
  ButtonComponent,
} from 'components'
import {
  BillingEmails,
  CreditCard,
  MaturityOption,
  Observation,
  PaymentMethodTabs,
  PaymentOption,
  PaymentOrdersCard,
} from './components'

import helpers from 'helpers'

import schema from './schema'
import * as service from 'service'
import { routes } from 'Routes'

import constants from 'constants/index'

const PaymentsForm = ({ paymentContract, mode }) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [changeCard, setChangeCard] = useState(false)
  const [infoOpen, setInfoOpen] = useState({ open: false, text: '', title: '' })
  const [sendEmails, setSendEmails] = useState(!paymentContract?.creditCard)
  const [methodPayment, setMethodPayment] = useState(
    paymentContract?.methodPayment || 'billet',
  )

  const validateCreditCard =
    (!paymentContract?.creditCard || changeCard) && methodPayment === 'card'

  const history = useHistory()

  const LIST_PAYMENT_PERMITTED = constants.permissions.PAYMENT_ORDERS.LIST

  const isShow = mode === 'show'

  const handleRedirect = () => {
    const action = isShow ? 'show' : 'edit'

    history.push(
      reverse(routes.contracts[action], {
        contractId: paymentContract?.id,
      }),
      { tab: 'contractor' },
    )
  }

  const {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
    clearError,
    register,
  } = useForm({
    validationSchema: schema(validateCreditCard, methodPayment),
    defaultValues: {
      billingDay:
        paymentContract?.billingDay || (moment().date() + 7).toString(),
      daysAdvance: paymentContract?.daysAdvance || moment().date().toString(),
      typeInvoice: paymentContract?.typeInvoice || 'monthly',
      expirationAction: paymentContract?.expirationAction || 'postpone',
      expirationLastMonth: isNil(paymentContract?.expirationLastMonth)
        ? 'false'
        : paymentContract?.expirationLastMonth.toString(),
      terms:
        paymentContract?.terms ||
        constants.preRegistration.TERM_PAYMENT_DEFAULT,
      observations: paymentContract?.observations || '',
      price: helpers.formatters.currencyInput(paymentContract?.price) || '',
      kind: paymentContract?.kind || 'recurrence',
      typeBillingDay:
        paymentContract?.typeBillingDay ||
        constants.paymentOrder.TYPE_BILLING_DAY_FIXED,
      billingDayDynamic: paymentContract?.billingDayDynamic || 7,
      gateway: paymentContract?.gateway || 'superfin',
      payInBackoffice:
        (paymentContract?.payInBackoffice || !!paymentContract.creditCard) ??
        false,
      card: {
        id: paymentContract?.creditCard?.id,
        name: '',
        cvv: '',
        expiration: '',
        number: '',
        brand: constants.creditCards.BRANDS[0].id,
      },
    },
  })

  const onSubmit = async (data) => {
    if (isShow) return null

    setLoading(true)
    try {
      if (paymentContract) {
        const response = await service.dponet.paymentContract.update({
          paymentContractId: paymentContract?.id,
          paymentContract: {
            ...data,
            methodPayment,
            sendEmails,
            changeCard,
            step: 'payment',
          },
        })

        const partial = response?.status === 207

        snackbar.open({
          message: partial
            ? response?.data?.message ?? 'Essa contrato foi parcialmente salva!'
            : 'Contrato atualizado com sucesso!',
          variant: partial ? 'warning' : 'success',
        })

        history.push(routes.contracts.all)
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao salvar o contrato',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    register('payInBackoffice')
    register('billingDay')
  }, [register])

  const payInBackoffice = watch('payInBackoffice')
  const initialStatus = !payInBackoffice && !paymentContract?.havePaymentOrders

  return (
    <>
      <LoadingFeedback open={loading} />
      <form onSubmit={handleSubmit(onSubmit)} id="payment-form">
        <>
          <PaymentMethodTabs
            setValue={setMethodPayment}
            method={methodPayment}
            setMethod={setMethodPayment}
            clearErrors={clearError}
          />
          <MaturityOption
            control={control}
            defaultExpanded={
              paymentContract?.typeBillingDay === 'dynamic' &&
              !paymentContract?.havePaymentOrders
            }
            errors={errors}
            disabled={isShow}
            style={{
              display: methodPayment === 'card' ? 'none' : 'block',
            }}
          />
          <PaymentOption
            control={control}
            errors={errors}
            mode={mode}
            methodPayment={methodPayment}
            watch={watch}
            setInfoOpen={setInfoOpen}
            paymentContract={paymentContract}
            initialStatus={initialStatus}
            setValue={setValue}
          />
          {methodPayment === 'card' && (
            <CreditCard
              changeCard={changeCard}
              setChangeCard={setChangeCard}
              paymentContract={paymentContract}
              sendEmails={sendEmails}
              setSendEmails={setSendEmails}
              disabled={isShow}
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
            />
          )}
          <BillingEmails
            disabled={isShow}
            paymentContractId={paymentContract?.id}
          />
          <Observation control={control} errors={errors} isShow={isShow} />
          <Box display="flex" justifyContent="flex-end" my={2}>
            <Box mr={2}>
              <ButtonComponent
                variant="outlined"
                type="button"
                onClick={handleRedirect}
                title="Voltar"
              />
            </Box>
            {!isShow && (
              <ButtonComponent
                form="payment-form"
                title={helpers.paymentOrders.saveText(
                  paymentContract?.havePaymentOrders,
                  methodPayment,
                  payInBackoffice,
                  sendEmails,
                )}
              />
            )}
          </Box>
        </>
      </form>
      {paymentContract?.havePaymentOrders && (
        <Permitted tag={LIST_PAYMENT_PERMITTED}>
          <PaymentOrdersCard paymentContract={paymentContract} />
        </Permitted>
      )}
      {infoOpen?.open && (
        <InfoDialog
          open={infoOpen?.open}
          setOpen={setInfoOpen}
          text={infoOpen?.text}
          title={infoOpen?.title}
        />
      )}
    </>
  )
}

PaymentsForm.propTypes = {
  paymentContract: PropTypes.object,
  preRegistrationToken: PropTypes.string,
  planPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mode: PropTypes.string,
}

export default PaymentsForm
