import * as yup from 'yup'

const schemaIntegrationPartner = yup.object().shape({
  name: yup.string().required(),
  platform: yup.string().required(),
  planId: yup.string().required(),
  cookiePlan: yup.string().required(),
})

export default schemaIntegrationPartner
