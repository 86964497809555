import * as yup from 'yup'

const schemaFaq = yup.object().shape({
  faqsDocumentationsCategoryId: yup.string().required(),
  visualization: yup.string().required(),
  title: yup.string().required(),
  subtitle: yup.string().required(),
  description: yup.string().required(),
})

export default schemaFaq
