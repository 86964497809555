import { Chip } from '@material-ui/core'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'

import ActionButtons from '../ActionButtons'

import constants from 'constants/index'
import helpers from 'helpers'
import useStyles from './styles'

import PropTypes from 'prop-types'

const ChurnsTableRow = ({ churn }) => {
  const classes = useStyles()

  return (
    <>
      <TableRow key={churn?.id}>
        <TableCell>{churn?.id}</TableCell>
        <TableCell>{churn?.company?.name}</TableCell>
        <TableCell>
          {helpers.formatters.documentFormatter(churn?.company?.document)}
        </TableCell>
        <TableCell>{churn?.responsible?.name}</TableCell>
        <TableCell align="center">{churn?.ticketId}</TableCell>
        <TableCell align="center">
          {helpers.formatters.date(churn?.createdAt)}
        </TableCell>
        <TableCell align="center" disableTooltip>
          <Chip
            align="center"
            size="small"
            label={helpers.churns.statusTranslation(churn?.status)}
            className={classes.chip}
            style={{
              backgroundColor: helpers.churns.chipColor(churn?.status),
            }}
          />
        </TableCell>
        <TableCell align="center" disableTooltip>
          <Permitted
            someTags={[
              constants.permissions.CHURNS.MANAGE,
              constants.permissions.CHURNS.SHOW,
            ]}
          >
            <MenuButton>
              <ActionButtons churn={churn} />
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

ChurnsTableRow.propTypes = {
  churn: PropTypes.object,
}

export default ChurnsTableRow
