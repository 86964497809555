import * as yup from 'yup'

const schemaDocumentation = yup.object().shape({
  title: yup.string().required(),
  onlyCollaborators: yup.string().required(),
  uri: yup.string().required(),
  subtitle: yup.string().required(),
  faqsDocumentationsCategoryId: yup.string().required(),
  description: yup.string().required(),
})

export default schemaDocumentation
