import tableCellColor from './tableCellColor'
import formattedValue from './formattedValue'
import changeLogs from './changeLogs'
import status from './status'
import billet from './billet'

const logs = {
  tableCellColor,
  formattedValue,
  changeLogs,
  status,
  billet,
}

export default logs
