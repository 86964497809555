import helpers from 'helpers'

const billet = ({ value, registerType }) => {
  if (registerType === 'PaymentOrder') {
    const { token, expirationDate } = value
    return `Token: ${token}\nData de Expiração: ${helpers.formatters.date(
      expirationDate,
    )}`
  }

  return JSON.stringify(value)
}

export default billet
