import permissions from '../../permissions'

const QUESTIONNAIRE_BY_COMPANY_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.QUESTIONNAIRE_BY_COMPANY.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.QUESTIONNAIRE_BY_COMPANY.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.QUESTIONNAIRE_BY_COMPANY.EDIT,
  },
]

export default QUESTIONNAIRE_BY_COMPANY_LIST
