import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'

import { Box, Grid, InputAdornment, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'

import { Card } from 'components'
import { DelinquencyTable } from '..'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
import PropTypes from 'prop-types'

import { useSnackbar } from 'hooks'

import schema from './schema'
import theme from 'theme'

const SolicitationStep = ({ churn, setLoading, handleNextStep, show }) => {
  const snackbar = useSnackbar()

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      ticketId: churn?.ticket?.id || '',
      document:
        helpers.formatters.cnpjAndCpfMask(
          churn?.paymentContract?.preRegistration?.company?.document,
        ) || '',
      company: churn?.paymentContract?.preRegistration?.company?.name || '',
      segment:
        churn?.paymentContract?.preRegistration?.plan?.segment?.name || '',
      companySize: churn?.paymentContract?.preRegistration?.plan?.code || '',
      contractDate: moment(churn?.paymentContract?.startDate) || '',
      churnDate: churn?.ticket?.createdAt || null,
      mrr: churn?.paymentContract?.price || '',
      seller: churn?.paymentContract?.preRegistration?.seller?.name || '',
      responsible: churn?.responsible?.name || '',
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      await service.dponet.churns.update({
        churnId: churn.id,
        ticketId: data.ticketId,
      })

      snackbar.open({
        message: `Churn atualizado com sucesso!`,
        variant: 'success',
      })
      handleNextStep()
    } catch (error) {
      snackbar.open({
        message: 'É necessário informar um ID do Ticket válido',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box
      component="form"
      id={constants.churn.CHURN_STEPPER_SOLICITATION_ID_FORM}
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(4)}
    >
      <Card title="Informações da empresa" paddingCard={0}>
        <Grid container spacing={2}>
          {constants.churn.COMPANY_INFO_CONTROL_ITEMS.map((item, index) => (
            <Grid key={index} item xs={12} md={item?.gridMd}>
              {item?.component === 'datePicker' ? (
                <Controller
                  as={
                    <DatePicker
                      format="DD/MM/yyyy"
                      label={item?.label}
                      onChange={(newValue) => ({
                        value: newValue,
                      })}
                      error={!!errors?.[item?.controlName]}
                      helperText={<>{errors?.[item?.controlName]?.message}</>}
                      InputProps={{ inputMode: item?.mode }}
                      fullWidth
                      disabled
                    />
                  }
                  control={control}
                  name={item?.controlName}
                  mode="onChange"
                />
              ) : (
                <Controller
                  as={
                    <TextField
                      type="text"
                      color="primary"
                      variant="outlined"
                      label={item?.label}
                      error={!!errors?.[item?.controlName]}
                      helperText={<>{errors?.[item?.controlName]?.message}</>}
                      fullWidth
                      disabled={
                        show ||
                        (item?.controlName === 'ticketId' ? false : true)
                      }
                      InputProps={{
                        inputMode: item?.mode,
                        ...(item?.controlName === 'mrr' && {
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        }),
                      }}
                    />
                  }
                  control={control}
                  name={item?.controlName}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Card>
      <DelinquencyTable churnId={churn.id} />
    </Box>
  )
}

SolicitationStep.propTypes = {
  churn: PropTypes.object,
  setLoading: PropTypes.func,
  handleNextStep: PropTypes.func,
  show: PropTypes.bool,
}

export default SolicitationStep
