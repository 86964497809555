import Main from './QuestionnairesMain'
import Edit from './QuestionnairesEdit'
import Show from './QuestionnairesShow'

const Questionnaires = {
  Main,
  Edit,
  Show,
}

export default Questionnaires
