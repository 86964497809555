import React, { useState, useEffect } from 'react'

import { debounce } from 'lodash'
import PropTypes from 'prop-types'

import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'

import * as service from 'service'

const ListContracts = ({ setSelectedContract }) => {
  const filter = useFilter()

  const [inputValue, setInputValue] = useState('')
  const [debouncedValue, setDebouncedValue] = useState('')

  const debouncedInput = debounce((value) => {
    setDebouncedValue(value)
  }, 1000)

  useEffect(() => {
    debouncedInput(inputValue)
    return () => {
      debouncedInput.cancel()
    }
  }, [inputValue, debouncedInput])

  const { response } = useFetch(
    service.dponet.paymentContract.lists,
    {
      identifierOrContractCont: debouncedValue,
      ...filter.filters,
      perPage: 50,
    },
    [debouncedValue],
    debouncedValue.length > 0,
  )

  const paymentContracts = response?.data?.paymentContracts

  const modifiedContracts =
    paymentContracts?.map((contract) => {
      const newIdentifier = `${contract?.identifier} - ${contract?.contractIdentifier}`

      return {
        ...contract,
        identifier: newIdentifier,
      }
    }) || []

  const extractIdentifier = (identifier) => {
    const regex = /#(\d+)\s*-\s*(.*?)\s*\(([\d./-]+)\)\s*-\s*(\d+\/\d+)/
    const match = identifier.match(regex)

    if (match) {
      const [
        ,
        preRegistrationId,
        companyName,
        companyDocument,
        contractIdentifier,
      ] = match
      return {
        preRegistrationId,
        companyName,
        companyDocument,
        contractIdentifier,
      }
    }

    return {
      preRegistrationId: '',
      companyName: '',
      companyDocument: '',
      contractIdentifier: '',
    }
  }

  const filteredContracts =
    inputValue.length >= 1
      ? modifiedContracts.filter((modifiedContract) => {
          const identifier = extractIdentifier(modifiedContract?.identifier)
          const contractId = identifier?.preRegistrationId
          const companyName = identifier?.companyName.toLowerCase()
          const companyDocument = identifier?.companyDocument
          const contractIdentifier = identifier?.contractIdentifier

          const normalize = (str) => str.replace(/[./-]/g, '')

          return (
            contractId.includes(inputValue) ||
            companyName.includes(inputValue.toLowerCase()) ||
            normalize(companyDocument).startsWith(normalize(inputValue)) ||
            contractIdentifier.includes(inputValue)
          )
        })
      : []

  return (
    <Autocomplete
      options={filteredContracts}
      getOptionLabel={(option) => option?.identifier || ''}
      getOptionSelected={(option, value) =>
        !value || option?.id === value?.id || value?.id === 0
      }
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onChange={(event, newValue) => {
        setSelectedContract(newValue)
      }}
      noOptionsText={
        inputValue.length < 1 ? 'Digite para buscar' : 'Sem Opções'
      }
      filterOptions={(options) => options}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Listagem de contratos"
          variant="outlined"
        />
      )}
    />
  )
}

ListContracts.propTypes = {
  setSelectedContract: PropTypes.func,
}

export default ListContracts
