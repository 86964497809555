import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton, PaymentOrderButtonAction, Permitted } from 'components'

import helpers from 'helpers'
import useStyles from './styles'
import constants from 'constants/index'

const PaymentOrdersTableRow = ({ paymentOrder, refresh, setLoading }) => {
  const classes = useStyles()

  return (
    <>
      <TableRow key={paymentOrder?.id}>
        <TableCell>{paymentOrder?.id}</TableCell>
        <TableCell>{paymentOrder?.paymentMethod || '-'}</TableCell>
        <TableCell>{paymentOrder?.gateway || '-'}</TableCell>
        <TableCell>
          R$ {helpers.formatters.currencyInput(paymentOrder?.amount)}
        </TableCell>
        <TableCell align="center">
          {paymentOrder?.billet?.expirationDate
            ? helpers.formatters.date(paymentOrder?.billet?.expirationDate)
            : '-'}
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(paymentOrder?.paidDate) || '-'}
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(
            paymentOrder?.orderReferenceDate,
            'MM/YYYY',
          ) || '-'}
        </TableCell>
        <TableCell>{paymentOrder?.dataNfe?.nNfse || '-'}</TableCell>
        <TableCell disableTooltip noOverFlow align="center">
          <Chip
            size="small"
            className={helpers.paymentOrders.chipStatusColor(
              paymentOrder?.status,
              classes,
            )}
            label={helpers.paymentOrders.chipStatusLabel(paymentOrder?.status)}
          />
        </TableCell>
        <TableCell disableTooltip noOverFlow>
          <Permitted tag={constants.permissions.PAYMENT_ORDERS.MANAGE_PAYMENT}>
            <MenuButton>
              <PaymentOrderButtonAction
                paymentOrder={paymentOrder}
                refresh={refresh}
                setLoading={setLoading}
              />
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

PaymentOrdersTableRow.propTypes = {
  paymentOrder: PropTypes.object,
}

export default PaymentOrdersTableRow
