import permissions from '../../permissions'

const PRE_REGISTRATION_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.PAYMENT_CONTRACTS.MANAGE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.PAYMENT_CONTRACTS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.SHOW,
    tag: permissions.PAYMENT_CONTRACTS.SHOW,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.INVOICE,
    tag: permissions.PAYMENT_CONTRACTS.INVOICE,
  },
]

export default PRE_REGISTRATION_LIST
