import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Plus as PlusIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'

import { useFetch, useFilter, usePagination } from 'hooks'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'

import PreRegistrationsTable from './components/SublicensingsTable'

import { routes } from 'Routes'
import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const SublicensingsMain = () => {
  const filter = useFilter()
  const history = useHistory()

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.preRegistrations.get,
    {
      ...{ ...filter.filters },
      typeClient: constants.preRegistration.SUBLICENSE_TYPE,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const { response: responseSeller } = useFetch(service.dponet.sellers.index, {
    kindNotIn: [constants.seller.PARTNER_KIND],
  })

  const handleCreation = () => {
    history.push(routes.preRegistrations.new, {
      type: constants.preRegistration.TYPE_REAL,
      typeClient: constants.preRegistration.SUBLICENSE_TYPE,
    })
  }

  return (
    <Page title="Sublicenciamentos">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader
          title="Listagem"
          menu="Sublicenciamentos"
          subtitle="Exibição dos sublicenciamentos"
        >
          <Box ml={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Card>
          <Box width="100%">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  p={2}
                  height="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="h5">Sublicenciamentos</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Permitted
                    tag={constants.permissions.PRE_REGISTRATIONS.CREATE}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<PlusIcon />}
                      onClick={handleCreation}
                    >
                      Criar
                    </Button>
                  </Permitted>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Box>
          <PreRegistrationsTable
            preRegistrations={response?.data?.preRegistrations || []}
            refresh={refresh}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      <Filters filter={filter}>
        <input textfieldinput="true" label="Identificador" name="id" />
        <input
          textfieldinput="true"
          label="Nome do sublicenciamento"
          name="name"
        />
        <input textfieldinput="true" label="Documento" name="document" />
        <select
          textfieldinput="true"
          label="Tipo do sublicenciamento"
          name="kind"
        >
          <option value="" />
          <option value={constants.companies.KINDS.COMPANY}>
            Pessoa jurídica
          </option>
          <option value={constants.companies.KINDS.PERSONAL}>
            Pessoa física
          </option>
        </select>
        <input
          datepickerinput="true"
          label="Data de criação inicial"
          name="createdAtGt"
          data-target="createdAtLt"
          data-min
        />
        <input
          datepickerinput="true"
          label="Data de criação final"
          name="createdAtLt"
          data-target="createdAtGt"
          data-max
        />
        <select textfieldinput="true" label="Status" name="status">
          <option value=""></option>
          <option value={constants.preRegistration.STATUSES.INITIATED_STATUS}>
            Iniciado
          </option>
          <option value={constants.preRegistration.STATUSES.FINALIZED_STATUS}>
            Finalizado
          </option>
          <option value={constants.preRegistration.STATUSES.DEMO_STATUS}>
            Demonstração
          </option>
          <option value={constants.preRegistration.STATUSES.TRIAL_STATUS}>
            Gratuito
          </option>
          <option
            value={constants.preRegistration.STATUSES.TRIAL_CANCELED_STATUS}
          >
            Gratuidade cancelada
          </option>
        </select>
        <select
          autocompleteselectinput="true"
          compareByName
          name="sellerName"
          label="Executivo de vendas"
          optionvalues={helpers.formatters.formatByNameInFilter(
            responseSeller?.data?.sellers,
          )}
        />

        <select textfieldinput="true" name="origin" label="Origem">
          {constants.preRegistration.OPTION_ORIGINS.map((origin) => (
            <option key={origin.id} value={origin.id}>
              {origin.name}
            </option>
          ))}
        </select>
      </Filters>
    </Page>
  )
}

export default SublicensingsMain
