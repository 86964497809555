import * as yup from 'yup'

const schema = yup.object().shape({
  categoryId: yup.string().required('Selecione alguma opção'),
  subCategoryId: yup.string().required(),
  title: yup.string().required().max(100),
  description: yup.string().required().max(12000),
})

export default schema
