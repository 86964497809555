import constants from 'constants/index'

const visualizationLabel = (visualization) => {
  const { VIEW_PERMISSION, VIEW_PERMISSION_LABEL } =
    constants.faqsDocumentations

  switch (visualization) {
    case VIEW_PERMISSION.COLLABORATOR:
      return VIEW_PERMISSION_LABEL.C
    case VIEW_PERMISSION.COLLABORATOR_AND_CLIENT:
      return VIEW_PERMISSION_LABEL.CC
    case VIEW_PERMISSION.COLLABORATOR_AND_ADVISOR:
      return VIEW_PERMISSION_LABEL.CA
    default:
      return 'Não definido'
  }
}

export default visualizationLabel
