const CONSULTIVE_SALES_LABEL = 'Consultive Sales'
const PUBLIC_AGENCY_LABEL = 'Órgãos Públicos'
const DIGITAL_SALES_LABEL = 'Digital Sales'
const SERVICES_LABEL = 'Serviços'

const TYPE_SERVICES_OPTIONS = [
  { id: 'Digital Sales', name: 'Digital Sales' },
  { id: 'Consultive Sales', name: 'Consultive Sales' },
]

const VERTICAL_DIGITAL_SALES_OPTIONS = [
  {
    id: 'Saúde - Operadora',
    name: 'Saúde - Operadora',
  },
  {
    id: 'Saúde - Clínica Médica',
    name: 'Saúde - Clínica Médica',
  },
  {
    id: 'Saúde - Hospital/Laboratório',
    name: 'Saúde - Hospital/Laboratório',
  },
  { id: 'Diversos', name: 'Diversos' },
  { id: 'Serviços', name: 'Serviços' },
  { id: 'Contabilidade', name: 'Contabilidade' },
  { id: 'Concessionária', name: 'Concessionária' },
  { id: 'Condomínio', name: 'Condomínio' },
]

const VERTICAL_CONSULTIVE_SALES_OPTIONS = [
  { id: 'Órgãos Públicos', name: 'Órgãos Públicos' },
]

const VERTICAL_OPTIONS = {
  'Digital Sales': VERTICAL_DIGITAL_SALES_OPTIONS,
  'Consultive Sales': VERTICAL_CONSULTIVE_SALES_OPTIONS,
}

const TABS = [
  {
    value: 'contract',
    label: 'Contrato',
  },
  {
    value: 'contractor',
    label: 'Empresas',
  },
  {
    value: 'payment',
    label: 'Pagamento',
  },
]

const paymentContract = {
  TYPE_SERVICES_OPTIONS,
  VERTICAL_OPTIONS,
  CONSULTIVE_SALES_LABEL,
  PUBLIC_AGENCY_LABEL,
  DIGITAL_SALES_LABEL,
  SERVICES_LABEL,
  TABS,
}

export default paymentContract
