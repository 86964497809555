import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers'

import { Controller, useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import { Label, BaseDialog, LoadingFeedback } from 'components'

import helpers from 'helpers'
import * as service from 'service'
import schema from './schema'

const Resend = ({
  open = false,
  setOpen = () => {},
  paymentOrderId,
  refresh = () => {},
}) => {
  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()
  const { control, errors, handleSubmit, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      newOrder: 'true',
      expirationBillingDate: moment().format('YYYY-MM-DD'),
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      data.expirationBillingDate = moment(data?.expirationBillingDate).format(
        'YYYY-MM-DD',
      )

      await service.dponet.paymentOrders.resend({
        paymentOrderId,
        paymentOrder: {
          ...data,
        },
      })

      snackbar.open({
        message: 'Reenvio de cobrança realizada com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao reenviar cobrança, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      title="Reenviar cobrança"
      actionButtonText="Confirmar"
      closeButtonText="Cancelar"
      setOpen={setOpen}
      formRef="id-new-order-resend"
    >
      <LoadingFeedback open={loading} />
      <form onSubmit={handleSubmit(onSubmit)} id="id-new-order-resend">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Label title="Tipo de cobrança">
              <Controller
                as={
                  <RadioGroup>
                    <Box>
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="Gerar uma nova cobrança"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="Não gerar uma nova cobrança (2ª via)"
                      />
                    </Box>
                  </RadioGroup>
                }
                control={control}
                mode="onChange"
                name="newOrder"
              />
            </Label>
          </Grid>
          {watch('newOrder') === 'true' && (
            <Grid item xs={12}>
              <Controller
                as={
                  <DatePicker
                    label="Vencimento do boleto"
                    color="primary"
                    format="DD/MM/YYYY"
                    variant="outlined"
                    error={!!errors.expirationBillingDate}
                    helperText={errors?.expirationBillingDate?.message}
                    fullWidth
                    disablePast
                    onChange={(newValue) => ({ value: newValue })}
                  />
                }
                control={control}
                name="expirationBillingDate"
                mode="onChange"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2">
              {watch('newOrder') === 'true'
                ? 'Será gerado uma nova cobrança utilizando a base da cobrança atual selecionada e com a ' +
                  'data de vencimento informada. A cobrança anterior será cancelada e não poderá ser paga.'
                : 'Não será gerado uma nova cobrança, apenas reenviaremos novamente a cobrança com os mesmos dados.'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {watch('newOrder') === 'true'
                ? 'Sera utilizado alguns dados da configuração de pagamento como valor e e-mail de cobrança.'
                : 'Se atente a data de vencimento da cobrança selecionada.'}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </BaseDialog>
  )
}

Resend.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  paymentOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refresh: PropTypes.func,
}

export default Resend
