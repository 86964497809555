import { useState } from 'react'
import {
  Button,
  Box,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
} from 'components'
import {
  PaymentOrdersTable,
  ExportConciliationPaymentsDialog,
} from './components'

import * as service from 'service'
import constants from 'constants/index'

const PaymentOrdersMain = () => {
  const filter = useFilter()
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading } = useFetch(
    service.dponet.paymentOrders.lists,
    {
      ...filter.filters,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const { response: responseSeller, isLoading: loadSeller } = useFetch(
    service.dponet.sellers.index,
    {
      kindNotIn: [constants.seller.PARTNER_KIND],
    },
  )

  return (
    <>
      <Page title="Ordem de pagamentos">
        <LoadingFeedback open={isLoading || loadSeller} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Ordem de pagamentos"
            subtitle="Listagem de ordem de pagamentos"
          >
            <Box mr={1}>
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                color="secondary"
              >
                EXPORTAR CONCILIAÇÃO
              </Button>
            </Box>
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Card>
            <Box width="100%">
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Ordem de pagamentos</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <PaymentOrdersTable
              paymentOrders={response?.data?.paymentOrders || []}
            />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input
            textfieldinput="true"
            label="Nome do cliente/sublicenciamento"
            name="buyerName"
          />
          <input
            textfieldinput="true"
            label="E-mail do cliente/sublicenciamento"
            name="buyerEmail"
          />
          <input
            textfieldinput="true"
            label="CNPJ/CPF do cliente/sublicenciamento"
            name="buyerDocument"
          />
          <input
            textfieldinput="true"
            label="Nº do contrato"
            name="contractIdentifier"
          />
          <select
            autocompleteselectinput="true"
            label="Método de pagamento"
            name="paymentMethod"
            optionvalues={constants.paymentOrder.PAYMENT_METHODS}
          />
          <select
            multipleselectinput="true"
            label="Status"
            name="statusIn"
            optionvalues={constants.paymentOrder.STATUS_OPTIONS}
          />
          <input
            datepickerinput="true"
            label="Data da confirmação de pagamento"
            name="paidDateEq"
          />
          <select
            autocompleteselectinput="true"
            compareByName
            name="sellerId"
            label="Executivo de vendas"
            optionvalues={responseSeller?.data?.sellers || []}
          />
        </Filters>
      </Page>
      {open && (
        <ExportConciliationPaymentsDialog open={open} setOpen={setOpen} />
      )}
    </>
  )
}

export default PaymentOrdersMain
