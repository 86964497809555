import { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import { BaseDialog, Permitted } from 'components'
import {
  HistoryPayment,
  ConfirmationPayment,
  ConfirmationLoss,
  Nfe,
  Resend,
  PaymentStep,
  Reckoning,
} from './components'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'
import { routes } from 'Routes'

const PaymentOrderButtonAction = ({
  paymentOrder,
  refresh = () => {},
  setLoading,
  isList = false,
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()

  const [openConfirmatedPaidDialog, setOpenConfirmatedPaidDialog] =
    useState(false)
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false)
  const [openConfirmatedLoss, setOpenConfirmatedLoss] = useState(false)

  const [openNfe, setOpenNfe] = useState(null)
  const [openResend, setOpenResend] = useState(false)
  const [openStep, setOpenStep] = useState(false)
  const [openReckoning, setReckoning] = useState(false)

  const { PAID_STATUS } = constants.paymentOrder.STATUSES

  const paymentOrderNotPaid = paymentOrder?.status !== PAID_STATUS

  const handleCancel = async () => {
    setLoading(true)
    try {
      await service.dponet.paymentOrders.cancel({
        paymentOrderId: paymentOrder?.id,
      })

      snackbar.open({
        message: 'Solicitação de cancelamento realizada com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao solicitar o cancelamento, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleOpenStep = () => setOpenStep(true)
  const handleOpenReckoning = () => setReckoning(true)
  const handleOpenNfe = () => setOpenNfe(true)

  const handleView = () =>
    history.push(
      reverse(routes.paymentOrders.show, {
        paymentOrderId: paymentOrder.id,
      }),
    )

  const handleBillet = async () => {
    setLoading(true)

    try {
      const response = await service.dponet.paymentOrders.billet({
        paymentOrderId: paymentOrder?.id,
      })

      window.open(response?.data, '_blank')

      snackbar.open({
        message: 'Boleto disponível para baixar!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao baixar o boleto, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Permitted tag={constants.permissions.PAYMENT_ORDERS.MANAGE_PAYMENT}>
        {isList && (
          <Button fullWidth size="small" onClick={handleView}>
            Visualizar
          </Button>
        )}
        <Button fullWidth size="small" onClick={handleBillet}>
          Baixar Boleto
        </Button>
        <Button fullWidth size="small" onClick={handleOpenNfe}>
          Nota fiscal
        </Button>
        {paymentOrderNotPaid && (
          <Button
            fullWidth
            size="small"
            onClick={() => {
              setOpenConfirmatedPaidDialog(true)
            }}
          >
            Confirmar pagamento
          </Button>
        )}
        <Button
          fullWidth
          size="small"
          onClick={() => {
            setOpenConfirmatedLoss(true)
          }}
        >
          Confirmar perda
        </Button>
        {!isEmpty(paymentOrder?.reckoningData) && (
          <Button fullWidth size="small" onClick={handleOpenReckoning}>
            Lançamento de conta a receber
          </Button>
        )}
        <Button fullWidth size="small" onClick={handleOpenStep}>
          Etapas da cobrança
        </Button>
        <Button
          fullWidth
          size="small"
          onClick={handleCancel}
          disabled={paymentOrder?.gateway === 'omie'}
        >
          Solicitar o cancelamento
        </Button>
        <Button
          fullWidth
          size="small"
          onClick={() => setOpenResend(true)}
          disabled={paymentOrder?.gateway === 'omie'}
        >
          Reenviar cobrança
        </Button>
        <Button
          fullWidth
          size="small"
          onClick={() => {
            setOpenHistoryDialog(true)
          }}
        >
          Histórico do pagamento
        </Button>
      </Permitted>

      {openConfirmatedPaidDialog && (
        <ConfirmationPayment
          open={openConfirmatedPaidDialog}
          setOpen={setOpenConfirmatedPaidDialog}
          refresh={refresh}
          setLoading={setLoading}
          paymentOrder={paymentOrder}
        />
      )}
      <ConfirmationLoss
        paymentOrderId={paymentOrder?.id}
        open={openConfirmatedLoss}
        setOpen={setOpenConfirmatedLoss}
        refresh={refresh}
      />
      <BaseDialog
        open={openHistoryDialog}
        setOpen={setOpenHistoryDialog}
        title="Histórico de pagamento"
        closeButtonText="Cancelar"
        dialogSize="sm"
        noMargin
      >
        <HistoryPayment paymentOrderId={paymentOrder?.id} />
      </BaseDialog>
      {openNfe && (
        <Nfe
          setOpen={setOpenNfe}
          open={openNfe}
          dataNfe={paymentOrder?.dataNfe}
          paymentOrderId={paymentOrder?.id}
          refresh={refresh}
          isOrderService={paymentOrder?.codeOrderServiceOmie}
        />
      )}
      {openResend && (
        <Resend
          open={openResend}
          setOpen={setOpenResend}
          refresh={refresh}
          paymentOrderId={paymentOrder?.id}
        />
      )}
      {openStep && (
        <PaymentStep
          open={openStep}
          setOpen={setOpenStep}
          paymentOrderId={paymentOrder?.id}
        />
      )}
      {openReckoning && (
        <Reckoning
          open={openReckoning}
          setOpen={setReckoning}
          reckoning={paymentOrder?.reckoningData}
          refresh={refresh}
        />
      )}
    </>
  )
}

PaymentOrderButtonAction.propTypes = {
  paymentOrder: PropTypes.object,
  refresh: PropTypes.func,
  isRelatedCompany: PropTypes.bool,
}

export default PaymentOrderButtonAction
