/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'

import { Card, LoadingBox, Select } from 'components'

import { routes } from 'Routes'

import useSnackbar from 'hooks/useSnackbar'
import { useFetch } from 'hooks'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

import { UpgradePlansTable } from 'views/IntegrationPartner/components'

import schema from './schema'
import useStyles from './styles'
import { reverse } from 'named-urls'

const Form = ({ integrationPartner, isEdit, refresh = () => {} }) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [upgradePlansState, setUpgradePlans] = useState([])

  const { handleSubmit, errors, control, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: integrationPartner?.name ?? '',
      platform: integrationPartner?.platform ?? '',
      planId: integrationPartner?.plan?.id ?? '',
      cookiePlan: integrationPartner?.cookiePlan ?? '',
    },
  })

  const platform = watch('platform')

  const { response, isLoading: isLoadingPlans } = useFetch(
    service.dponet.plans.get,
    { perPage: 9999, kind: 'integration', platform },
    [platform],
    !!platform,
  )

  const onSubmit = async (formData) => {
    setLoading(true)
    try {
      let response
      const data = {
        plans: [
          {
            planId: formData?.planId,
            main: true,
          },
          ...(upgradePlansState ?? []),
        ],
        ...formData,
      }

      if (isEdit) {
        await service.dponet.integrationPartners.put(
          integrationPartner?.id,
          data,
        )
      } else {
        response = await service.dponet.integrationPartners.create(data)
      }
      setUpgradePlans([])
      snackbar.open({
        message: `Parceiro ${isEdit ? 'editado' : 'criado'} com sucesso`,
        variant: 'success',
      })
      if (isEdit || !response?.data?.id) {
        return history.push(routes.integrationPartner.all)
      }

      history.push(
        reverse(routes.integrationPartner.edit, {
          integrationPartnerId: response?.data?.id,
        }),
      )
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error.response.data.error,
          false,
        ),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const plans = response?.data?.plans?.map((plan) => ({
    id: plan?.id,
    name: `${plan?.name} - ${plan?.code} - R$${plan?.price}`,
    price: plan?.price,
  }))

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card title={`${isEdit ? 'Editar' : 'Adicionar novo'} parceiro`}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>Nome</Typography>
                  <Controller
                    as={
                      <TextField
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        fullWidth
                      />
                    }
                    control={control}
                    name="name"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Plataforma:</Typography>
                  <Controller
                    control={control}
                    name="platform"
                    mode="onChange"
                    as={
                      <Select
                        items={
                          constants.integrationPartner
                            .SELECT_PLATFORM_INTEGRATION_PARTNER ?? []
                        }
                        error={!!errors.platform}
                        helperText={errors?.platform?.message}
                        variant="outlined"
                        fullWidth
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Plano de entrada:</Typography>
                  <Controller
                    control={control}
                    name="planId"
                    mode="onChange"
                    as={
                      <Select
                        disabled={!platform}
                        loading={!!platform && isLoadingPlans}
                        items={plans ?? []}
                        error={!!errors.planId}
                        helperText={errors?.planId?.message}
                        variant="outlined"
                        fullWidth
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Plano do banner de cookies:</Typography>
                  <Controller
                    control={control}
                    name="cookiePlan"
                    mode="onChange"
                    as={
                      <Select
                        items={
                          constants.integrationPartner
                            .SELECT_COOKIE_PLAN_INTEGRATION_PARTNER ?? []
                        }
                        error={!!errors.cookiePlan}
                        helperText={errors?.cookiePlan?.message}
                        variant="outlined"
                        fullWidth
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Box className={classes.boxBackSave}>
                <Box>
                  <Button
                    className={classes.buttonSave}
                    variant="contained"
                    type="submit"
                  >
                    SALVAR
                  </Button>
                </Box>
              </Box>
            </Card>
          </form>
          <UpgradePlansTable
            upgradePlans={
              integrationPartner?.upgradePlans ?? upgradePlansState ?? []
            }
            setUpgradePlans={setUpgradePlans}
            plans={plans}
            refresh={refresh}
            integrationPartner={integrationPartner}
          />
        </>
      )}
    </>
  )
}

Form.propTypes = {
  isEdit: PropTypes.bool,
  integrationPartner: PropTypes.object,
}

Form.defaultProps = {
  isEdit: false,
  integrationPartner: {},
}

export default Form
