import { useState, useEffect } from 'react'
import { useCompany } from 'hooks'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
  Box,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  CircularProgress,
  Checkbox,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { Card, PhoneInput } from 'components'
import { ResponsibleFormMenuItems } from './components'

import * as service from 'service'
import helpers from 'helpers'

const ResponsibleForm = ({
  errors,
  control,
  register,
  disableCertFields,
  setLoading,
  setChangeResponsibleDialog,
  editionPermitted,
  phoneSelected,
  setPhoneSelected,
  ...props
}) => {
  const [responsibleCandidates, setResponsibleCandidates] = useState([])
  const [
    responsibleCandidatesInputValues,
    setResponsibleCandidatesInputValues,
  ] = useState('')
  const [
    isLoadingResponsibleCandidatesInput,
    setIsLoadingResponsibleCandidatesInput,
  ] = useState(false)
  const [checkedProvideLegalFramework, setCheckedProvideLegalFramework] =
    useState(
      props?.company?.provideServiceLegalFramework ||
        props.company?.dpoIsDponet,
    )

  const { isLoading } = useCompany()

  const { instructionsText } = helpers.companies.typeAndSearch
  const responsibleCandidatesOptionsValues = isLoadingResponsibleCandidatesInput
    ? []
    : responsibleCandidates
  const phoneIsEmpty = isEmpty(phoneSelected)
  const [selectExistentResponsible, setSelectExistentResponsible] = useState(
    !!props?.company?.responsableUser?.id,
  )

  const handleChangeResponsibleSelection = () => {
    setLoading(true)
    setSelectExistentResponsible(!selectExistentResponsible)
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }

  const loadResponsibleCandidates = async () => {
    setIsLoadingResponsibleCandidatesInput(true)
    const responsibleCandidatesResponse = await service.dponet.user.get({
      minimal: true,
      perPage: 1000 * 1000,
      email: responsibleCandidatesInputValues,
    })

    const formattedResponsibleCandidates = formatResponsibleCandidates(
      responsibleCandidatesResponse?.data?.users,
    )

    setResponsibleCandidates(formattedResponsibleCandidates)
    setIsLoadingResponsibleCandidatesInput(false)
  }

  const formatResponsibleCandidates = (responsibleCandidates = []) => {
    return responsibleCandidates.map((responsibleCandidate) => ({
      id: responsibleCandidate?.id,
      name: responsibleCandidate?.email,
      phone: responsibleCandidate?.phone,
    }))
  }

  const handleTypeResponsibleCandidatesInput = (e) => {
    setResponsibleCandidatesInputValues(e.target.value)
  }

  useEffect(() => {
    const getResponsibleCandidates = setTimeout(() => {
      if (responsibleCandidatesInputValues.length < 3) return

      loadResponsibleCandidates()
    }, 1000)

    return () => clearTimeout(getResponsibleCandidates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsibleCandidatesInputValues])

  return (
    <Card
      title="Preencha as informações do responsável da empresa"
      menuButton
      actionButton={
        <ResponsibleFormMenuItems
          setChangeResponsibleDialog={setChangeResponsibleDialog}
          editionMode={disableCertFields}
        />
      }
    >
      <Box mb={1}>
        {!disableCertFields && (
          <Typography variant="body2" align="justify" gutterBottom>
            Caso você deseje vincular um usuário já existente como responsável
            da empresa, clique na opção{' '}
            <strong>Criar empresa com usuário existente</strong>, e
            posteriormente selecione o usuário que deseja vincular como
            responsável.
          </Typography>
        )}

        {!disableCertFields && (
          <Grid item xs={12} md={selectExistentResponsible ? 6 : 12}>
            <FormControlLabel
              control={
                <Switch
                  checked={selectExistentResponsible}
                  color="primary"
                  onChange={handleChangeResponsibleSelection}
                />
              }
              inputRef={register}
              name="selectExistentResponsible"
              labelPlacement="start"
              label="Criar empresa com usuário existente"
            />
          </Grid>
        )}
        <Grid container spacing={1} justifyContent="flex-end">
          {selectExistentResponsible && !disableCertFields ? (
            <>
              {!isLoading && (
                <>
                  <Grid item xs={12} md={phoneIsEmpty ? 6 : 12}>
                    <Controller
                      control={control}
                      name="responsibleUserId"
                      onChange={([, data]) => {
                        setPhoneSelected(data?.phone)
                        return data
                      }}
                      mode="onChange"
                      as={
                        <Autocomplete
                          disabled={disableCertFields}
                          options={responsibleCandidatesOptionsValues || []}
                          getOptionLabel={(option) => option.name || ''}
                          getOptionSelected={(option, value) =>
                            !value || option.id === value.id || value.id === 0
                          }
                          noOptionsText={instructionsText(
                            responsibleCandidatesInputValues,
                          )}
                          loading={isLoadingResponsibleCandidatesInput}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={handleTypeResponsibleCandidatesInput}
                              error={!!errors?.responsibleUserId}
                              helperText={
                                errors?.responsibleUserId
                                  ? 'Selecione um usuário'
                                  : ''
                              }
                              label="Selecione o usuário que deseja tornar represente"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isLoadingResponsibleCandidatesInput ? (
                                      <CircularProgress
                                        color="primary"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      }
                    />
                  </Grid>
                  {phoneIsEmpty && (
                    <Grid item xs={12} md={6}>
                      <Grid>
                        <PhoneInput
                          control={control}
                          errors={errors}
                          fieldName="responsiblePhone"
                          label="Telefone da responsável pela empresa"
                          phoneNumber={
                            props?.company?.responsableUser?.phone || ''
                          }
                          editionPermitted={editionPermitted && phoneIsEmpty}
                          editionMode={disableCertFields}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {disableCertFields && !props.company?.dpoIsDponet && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        disabled={props.company?.dpoIsDponet}
                        checked={checkedProvideLegalFramework}
                        onChange={() =>
                          setCheckedProvideLegalFramework(
                            !checkedProvideLegalFramework,
                          )
                        }
                      />
                    }
                    inputRef={register}
                    name="provideServiceLegalFramework"
                    labelPlacement="end"
                    label="Enquadramento legal feito pela DPOnet"
                  />
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item md={6} xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      error={!!errors.responsibleName}
                      helperText={errors?.responsibleName?.message}
                      label="Nome do responsável pela empresa"
                      disabled={disableCertFields}
                    />
                  }
                  control={control}
                  name="responsibleName"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      error={!!errors.responsibleEmail}
                      helperText={errors?.responsibleEmail?.message}
                      label="E-mail do responsável pela empresa"
                      disabled={disableCertFields}
                    />
                  }
                  control={control}
                  name="responsibleEmail"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item md={6} xs={12}>
                  <PhoneInput
                    control={control}
                    errors={errors}
                    fieldName="responsiblePhone"
                    label="Telefone da responsável pela empresa"
                    phoneNumber={props?.company?.responsableUser?.phone || ''}
                    editionPermitted={editionPermitted}
                    editionMode={disableCertFields}
                  />
                </Grid>
              </Grid>
              {disableCertFields && !props.company?.dpoIsDponet && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={checkedProvideLegalFramework}
                        onChange={() =>
                          setCheckedProvideLegalFramework(
                            !checkedProvideLegalFramework,
                          )
                        }
                      />
                    }
                    inputRef={register}
                    name="provideServiceLegalFramework"
                    labelPlacement="end"
                    label="Enquadramento legal feito pela DPOnet"
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Card>
  )
}

ResponsibleForm.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func,
  disableCertFields: PropTypes.bool,
  setLoading: PropTypes.func,
  setChangeResponsibleDialog: PropTypes.func,
  editionPermitted: PropTypes.bool,
  phoneSelected: PropTypes.string,
  setPhoneSelected: PropTypes.func,
}

ResponsibleForm.defaultProps = {
  register: () => {},
  setLoading: () => {},
  setChangeResponsibleDialog: () => {},
}

export default ResponsibleForm
