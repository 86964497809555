import PropTypes from 'prop-types'
import { Button, Typography } from '@material-ui/core'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'
import { getBaseURL } from 'service/env'
import useStyles from './styles'

const ContractsTableRow = ({ paymentContract }) => {
  const history = useHistory()
  const classes = useStyles()

  const { PAYMENT_CONTRACTS } = constants.permissions

  const handleShowInvoice = () => {
    if (paymentContract?.invoiceUrl) {
      window.open(
        `${getBaseURL('dponet')}/${paymentContract?.invoiceUrl}`,
        '__blank',
      )
    }
  }

  const handleShowContract = () => {
    if (paymentContract?.platformContractUrl) {
      window.open(paymentContract.platformContractUrl, '__blank')
    }
  }

  const handlePreRegistration = () => {
    history.push(
      reverse(routes.preRegistrations.show, {
        preRegistrationToken: paymentContract?.preRegistrationToken,
      }),
    )
  }

  const handleEdit = () => {
    history.push(
      reverse(routes.contracts.edit, {
        contractId: paymentContract?.id,
      }),
    )
  }

  const handleShow = () => {
    history.push(
      reverse(routes.contracts.show, {
        contractId: paymentContract?.id,
      }),
    )
  }

  return (
    <TableRow key={paymentContract?.id}>
      <TableCell>#{paymentContract?.id}</TableCell>
      <TableCell>{paymentContract?.contractIdentifier} </TableCell>
      <TableCell pointer overFlowSize="100%">
        <Typography
          variant="body2"
          onClick={handlePreRegistration}
          className={classes.link}
        >
          {paymentContract?.identifier}
        </Typography>
      </TableCell>
      <TableCell>
        {constants.preRegistration.KIND_PAYMENT_FEATURES[paymentContract?.kind]}
      </TableCell>
      <TableCell>
        R$ {helpers.formatters.currencyInput(paymentContract?.price)}
      </TableCell>
      <TableCell>
        {helpers.formatters.date(paymentContract?.nextDateAdvance) || '-'}
      </TableCell>
      <TableCell>
        {helpers.formatters.date(paymentContract?.nextBillingDate) || '-'}
      </TableCell>
      <TableCell disableTooltip noOverFlow align="center">
        <MenuButton>
          <Permitted tag={PAYMENT_CONTRACTS.MANAGE}>
            <Button fullWidth size="small" onClick={handleEdit}>
              Editar
            </Button>
          </Permitted>
          <Permitted tag={PAYMENT_CONTRACTS.SHOW}>
            <Button fullWidth size="small" onClick={handleShow}>
              Visualizar
            </Button>
          </Permitted>
          <Button
            fullWidth
            size="small"
            onClick={handleShowContract}
            disabled={!paymentContract?.platformContractUrl}
          >
            Contrato
          </Button>
          <Button
            fullWidth
            size="small"
            onClick={handleShowInvoice}
            disabled={!paymentContract?.codeContractOmie}
          >
            Recibo
          </Button>
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

ContractsTableRow.propTypes = {
  paymentContract: PropTypes.object,
}

export default ContractsTableRow
