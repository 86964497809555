import usePermissions from 'hooks/usePermissions'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'

import { Box, Button, Chip } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'

import PerfectScrollbar from 'react-perfect-scrollbar'
import useStyles from './styles'
import helpers from 'helpers'
import { routes } from 'Routes'
import constants from 'constants/index'
import ActionButtons from '../ActionButtons'

const TableRowCompany = ({ company, refresh, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()
  const permissions = usePermissions()

  const navigateToShow = (companyId) => {
    history.push(
      reverse(routes.companies.edit, {
        companyId: companyId,
      }),
    )
  }

  const actionLabel = () => {
    if (permissions.permitted(constants.permissions.COMPANIES.UPDATE)) {
      return 'Editar'
    } else {
      return 'Visualizar'
    }
  }

  return (
    <TableRow {...rest}>
      <TableCell>{company?.id}</TableCell>
      <TableCell>{company?.name}</TableCell>
      <TableCell>{company?.document || '-'}</TableCell>
      <TableCell>{company?.companyType}</TableCell>
      <TableCell disableTooltip>
        <Chip
          size="small"
          className={helpers.companies.chipStatusColor(
            company?.statusId,
            classes,
          )}
          label={helpers.companies.statusLabel(
            company?.statusId,
            company?.platfom,
          )}
        />
      </TableCell>
      <TableCell>{helpers.formatters.date(company?.createdAt)}</TableCell>
      <TableCell>
        {helpers.formatters.date(company?.userStepsConcludedAt) ?? 'Pendente'}
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button
            fullWidth
            size="small"
            onClick={() => {
              navigateToShow(company?.id)
            }}
          >
            {actionLabel()}
          </Button>
          <ActionButtons company={company} refresh={refresh} />
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

const CompaniesTable = ({ companies, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: true, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhuma empresa encontrada" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="15%" align="left">
                Nome
              </TableCell>
              <TableCell width="15%" align="left">
                Documento
              </TableCell>
              <TableCell width="15%" align="left">
                Tipo
              </TableCell>
              <TableCell width="15%" align="left">
                Status
              </TableCell>
              <TableCell width="15%" align="left">
                Data de criação
              </TableCell>
              <TableCell width="15%" align="left">
                Primeiro acesso
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies?.map((company) => (
              <TableRowCompany
                company={company}
                key={company?.id}
                refresh={refresh}
                hover
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

CompaniesTable.propTypes = {
  company: PropTypes.object,
  refresh: PropTypes.func,
}

CompaniesTable.defaultProps = {
  refresh: () => {},
}
export default CompaniesTable
