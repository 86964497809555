/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import PropTypes from 'prop-types'

import { isEmpty, isUndefined } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'

import { Card, LoadingBox, TextEditor, Select } from 'components'

import { routes } from 'Routes'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import schema from './schema'
import constants from 'constants/index'
import useStyles from './styles'
import useFetch from 'hooks/useFetch'

const Form = ({ faq, isEdit }) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [draft, setDraft] = useState(true)

  const { handleSubmit, errors, control, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      faqsDocumentationsCategoryId: faq?.faqsDocumentationsCategoryId ?? '',
      visualization: faq?.visualization ?? '',
      title: faq?.title ?? '',
      subtitle: faq?.subtitle ?? '',
      description: faq?.description ?? '',
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      if (!isUndefined(draft) || !isEmpty(draft)) {
        if (isEdit) {
          await service.dponet.faqsDocumentations.put({
            faqsDocumentationId: faq?.id,
            draft: draft,
            ...data,
          })
        } else {
          await service.dponet.faqsDocumentations.create({
            draft: draft,
            kind: constants.faqsDocumentations.KINDS.FAQ,
            ...data,
          })
        }
      }
      snackbar.open({
        message: `FAQ ${isEdit ? 'editada' : 'criada'} com sucesso`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error.response.data.error,
          false,
        ),
        variant: 'error',
      })
    }
    setLoading(false)
    reset(data)
    history.push(routes.faqs.all)
  }

  const { response, isLoading } = useFetch(
    service.dponet.faqsDocumentationsCategories.get,
    {
      categoryType: 'faq_category',
    },
    [],
  )
  const optionsCategories = response?.data?.faqsDocumentationsCategories

  return (
    <>
      {loading && isLoading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card title={`${isEdit ? 'Editar' : 'Adicionar nova'} FAQ`}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography>Categoria:</Typography>
                <Controller
                  control={control}
                  name="faqsDocumentationsCategoryId"
                  mode="onChange"
                  as={
                    <Select
                      items={optionsCategories ?? []}
                      error={!!errors.faqsDocumentationsCategoryId}
                      helperText={errors?.faqsDocumentationsCategoryId?.message}
                      variant="outlined"
                      fullWidth
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Visualização:</Typography>
                <Controller
                  control={control}
                  name="visualization"
                  mode="onChange"
                  as={
                    <Select
                      items={
                        constants.faqsDocumentations.SELECT_VIEW_PERMISSION
                      }
                      error={!!errors.visualization}
                      helperText={errors?.visualization?.message}
                      variant="outlined"
                      fullWidth
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Título</Typography>
                <Controller
                  as={
                    <TextField
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.title}
                      helperText={errors?.title?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="title"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Subtítulo</Typography>
                <Controller
                  as={
                    <TextField
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.subtitle}
                      helperText={errors?.subtitle?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="subtitle"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={({ onChange, value }) => (
                    <TextEditor onChange={onChange} value={value} />
                  )}
                  control={control}
                  name="description"
                  mode="onChange"
                />
                {errors.description && (
                  <Typography className={classes.errorDescriptionMessage}>
                    Preencha esse campo
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Box className={classes.boxBackSave}>
              <Box pr={1}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => setDraft(true)}
                >
                  SALVAR RASCUNHO
                </Button>
              </Box>
              <Box>
                <Button
                  className={classes.buttonSave}
                  variant="contained"
                  type="submit"
                  onClick={() => setDraft(false)}
                >
                  PUBLICAR
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </>
  )
}
Form.propTypes = {
  isEdit: PropTypes.bool,
  faq: PropTypes.object,
}

Form.defaultProps = {
  isEdit: false,
  faq: {},
}

export default Form
