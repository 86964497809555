import chipStatusColor from './chipStatusColor'
import chipStatusLabel from './chipStatusLabel'
import getStepApi from './getStepApi'
import mountConfirmationPay from './mountConfirmationPay'
import saveText from './saveText'

const paymentOrders = {
  chipStatusColor,
  chipStatusLabel,
  getStepApi,
  mountConfirmationPay,
  saveText,
}

export default paymentOrders
