import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Link,
  IconButton,
  Grid,
} from '@material-ui/core'
import { Sync as SyncIcon } from '@material-ui/icons/'

import useSnackbar from 'hooks/useSnackbar'

import { Label, LoadingFeedback } from 'components'
import { FormNfe } from './components'
import constants from 'constants/index'
import useStyles from '../../styles'
import * as service from 'service'
import helpers from 'helpers'

const Nfe = ({
  dataNfe,
  paymentOrderId,
  refresh,
  open,
  setOpen,
  isOrderService,
}) => {
  const snackbar = useSnackbar()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [linkedNfe, setLinkedNfe] = useState(false)
  const redirectViewFile = (url) => window.open(url, '_blank')

  const handleChangeStatus = async () => {
    setIsLoading(true)
    try {
      await service.dponet.paymentOrders.changeNfe({
        paymentOrderId,
      })

      snackbar.open({
        message: 'Verificação de atualização finalizada com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao Verificar atualizar do status da nota fiscal!',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const closeDialog = () => setOpen(false)
  const handleLinkedNfe = () => setLinkedNfe(!linkedNfe)

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">Dados da nota fiscal</Typography>
          <Button
            type="button"
            variant={linkedNfe ? 'outlined' : 'contained'}
            onClick={handleLinkedNfe}
            color="secondary"
          >
            {linkedNfe ? 'Voltar' : ' Vincular nota fiscal'}
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box my={1}>
          <Box p={2}>
            <LoadingFeedback open={isLoading} />
            {linkedNfe ? (
              <FormNfe
                paymentOrderId={paymentOrderId}
                refresh={refresh}
                setIsLoading={setIsLoading}
                setLinkedNfe={setLinkedNfe}
              />
            ) : (
              <Grid spacing={2} container>
                <Grid item xs={12} md={4}>
                  <Label
                    title="Código de Verificação"
                    description={dataNfe?.cCodVerif || 'Não disponível'}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Label
                    title="Número da nota fiscal"
                    description={dataNfe?.nNfse || 'Não disponível'}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Label
                    title="Número do Recibo Provisório de Serviços"
                    description={dataNfe?.nRps || 'Não disponível'}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Label
                    title="Nota fiscal"
                    description={
                      dataNfe?.cUrlNfse ? (
                        <Link
                          href="#"
                          onClick={() => redirectViewFile(dataNfe?.cUrlNfse)}
                        >
                          Baixar
                        </Link>
                      ) : (
                        'Não disponível'
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Label
                    title="Danfe"
                    description={
                      dataNfe?.danfe ? (
                        <Link
                          href="#"
                          onClick={() => redirectViewFile(dataNfe?.danfe)}
                        >
                          Baixar
                        </Link>
                      ) : (
                        'Não disponível'
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Label
                    title="XML da nota fiscal"
                    description={
                      dataNfe?.xmlDistr ? (
                        <Link
                          href="#"
                          onClick={() => redirectViewFile(dataNfe?.xmlDistr)}
                        >
                          Baixar
                        </Link>
                      ) : (
                        'Não disponível'
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Label
                    tooltip={false}
                    title="Status da nota fiscal"
                    description={
                      <>
                        <Typography variant="caption" color="textSecondary">
                          {constants.paymentOrder.STATUS_NFE[
                            dataNfe?.cStatusRps
                          ] || 'Não disponível'}
                        </Typography>
                        {isOrderService && (
                          <Box component="span" ml={2}>
                            <IconButton
                              size="small"
                              onClick={() => handleChangeStatus()}
                              color="primary"
                              className={classes.iconbutton}
                            >
                              <SyncIcon />
                            </IconButton>
                            <Typography variant="caption" color="textSecondary">
                              Atualizar
                            </Typography>
                          </Box>
                        )}
                      </>
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions p={2}>
        <Box px={2}>
          <Button type="button" variant="outlined" onClick={closeDialog}>
            Fechar
          </Button>
        </Box>
        {linkedNfe && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            form="nfe-form"
          >
            Atualizar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

Nfe.propTypes = {
  dataNfe: PropTypes.object,
}

export default Nfe
