const typeCompany = (preRegistration) => {
  const statuses = {
    TRIAL: 'Trial',
    TRIAL_CANCELLED: 'Trial',
  }

  const kinded = {
    T: 'Template',
    S: 'Fornecedora',
    C: 'Real (Jurídica)',
    P: 'Real (Física)',
  }

  return statuses[preRegistration?.status] || kinded[preRegistration?.kind]
}

export default typeCompany
